import React from 'react';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { Text, Button } from 'components/common/ComponentLibrary';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { getUrlCounter } from 'utilities/urls.utils';
import { AnimatedCounter } from './AnimatedCounter';
import useCount from 'hooks/counters/useCount';

const CounterCompact = ({ counter }: { counter: CounterInterface }) => {
  const { counterValue, incrementCount, decrementCount } = useCount(counter);

  const handleCount = async (increment: boolean) => {
    if (increment) {
      await incrementCount();
      return;
    }

    await decrementCount();
  };

  return (
    <a href={getUrlCounter(counter)}>
      <div className='shadow-[0_4px_15px_rgb(0,0,0,0.22)] md:shadow-[0_2px_10px_rgb(0,0,0,0.22)] drop-shadow-md bg-white dark:bg-neutral-800 p-3 rounded-2xl w-full md:w-auto flex flex-col gap-2'>
        <div className='flex gap-1 items-center justify-between'>
          <Text fontWeight='semibold' size='m' lineClamp={1}>
            {counter.name}
          </Text>
        </div>
        <div className='flex items-center justify-between'>
          <AnimatedCounter counterValue={counterValue} size={45} />
          <div className='flex items-center gap-10'>
            <Button
              color='red'
              size='m'
              width='square'
              onClick={(e) => {
                e.preventDefault();
                handleCount(false);
              }}
              status={counterValue === 0 ? 'disabled' : ''}
            >
              <Button.Icon icon={<MinusIcon className='w-8' />} />
            </Button>
            <Button
              size='m'
              width='square'
              onClick={(e) => {
                e.preventDefault();
                handleCount(true);
              }}
            >
              <Button.Icon icon={<PlusIcon className='w-8' />} />
            </Button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CounterCompact;
