import React from 'react';

import Counter from 'components/counters/Counter';
import CounterCompact from 'components/counters/CounterCompact';
import useCounters from 'hooks/counters/useCounters';
import CreateCounterPromo from 'components/counters/CreateCounterPromo';
import { useProfileStore } from 'store/profile.store';
import { STYLE } from 'constants/profiles.constants';

const CounterList = () => {
  const [profile] = useProfileStore((state) => [state.profile]);
  const { counters, isLoading, error } = useCounters();
  if (!profile || isLoading || error) {
    return <CreateCounterPromo />;
  }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8'>
      {counters.map((counter) => {
        switch (profile.style) {
          case STYLE.COMPACT:
            return <CounterCompact key={counter.id} counter={counter} />;
          case STYLE.NORMAL:
          default:
            return <Counter key={counter.id} counter={counter} />;
        }
      })}
    </div>
  );
};

export default CounterList;
