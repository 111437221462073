import { RxDatabase, addRxPlugin, createRxDatabase } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { DATABASE_NAME_INDEX_DB } from 'constants/application.constants';
import { profileSchema } from './profile/profile.schema';
import supabase from '../supabase/supabase';
import { ProfileCollection } from './profile/profile.collection';
import { defaultProfileDocumentMethods } from './profile/profile.document';
import { getEnvironmentVariable } from 'utilities/env.utils';
import { SupabaseReplication } from 'utilities/supabase-replication';
import { CountersCollection, defaultCountersCollectionsMethods } from './counters/counters.collection';
import { countersSchema } from './counters/counters.schema';
import { defaultCountersDocumentMethods } from './counters/counters.document';
import { STYLE } from 'constants/profiles.constants';
import { CounterChangesCollection } from './counterChanges/counterChanges.collection';
import { counterChangesSchema } from './counterChanges/counterChanges.schema';
import { defaultCounterChangesDocumentMethods } from './counterChanges/counterChanges.document';

addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

export type TallyTabbyCollections = {
  profiles: ProfileCollection;
  counters: CountersCollection;
  counterchanges: CounterChangesCollection;
};
export type ITallyTabbyDatabase = RxDatabase<TallyTabbyCollections>;

export const database: ITallyTabbyDatabase = await (async () => {
  // try {
  //   await removeRxDatabase(DATABASE_NAME_INDEX_DB, getRxStorageDexie());
  // } catch {
  //   // ignore
  // }

  const {
    data: { session },
  } = await supabase.auth.getSession();

  const db = await createRxDatabase<ITallyTabbyDatabase>({
    name: DATABASE_NAME_INDEX_DB,
    storage: getRxStorageDexie(),
    multiInstance: false,
  });

  const collections = await db.addCollections({
    profiles: {
      schema: profileSchema,
      methods: defaultProfileDocumentMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
        2: (oldDoc) => {
          return {
            ...oldDoc,
            style: STYLE.NORMAL,
          };
        },
      },
    },
    counters: {
      schema: countersSchema,
      methods: defaultCountersDocumentMethods,
      statics: defaultCountersCollectionsMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
    counterchanges: {
      schema: counterChangesSchema,
      methods: defaultCounterChangesDocumentMethods,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
      },
    },
  });

  if (session) {
    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.profiles,
      replicationIdentifier: `profileshttps://${getEnvironmentVariable('SUPABASE_PROJECT_REF')}.supabase.co`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });

    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.counters,
      replicationIdentifier: `countershttps://${getEnvironmentVariable('SUPABASE_PROJECT_REF')}.supabase.co`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });

    new SupabaseReplication({
      supabaseClient: supabase,
      collection: collections.counterchanges,
      replicationIdentifier: `counterchangeshttps://${getEnvironmentVariable('SUPABASE_PROJECT_REF')}.supabase.co`,
      pull: {
        lastModifiedField: 'updated_at',
      },
      push: {},
    });
  }

  return db;
})();
