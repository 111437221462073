import React from 'react';
import { Heading } from 'components/common/ComponentLibrary';
import Footer from 'components/application/Footer';
import { ResetPasswordForm } from 'components/application/ResetPassword';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const ResetPasswordPage = () => {
  useDocumentTitle('Change password');

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <div className='m-auto max-w-sm px-4 md:px-0'>
        <div className='space-y-4'>
          <Heading size='xl' textAlign='center' fontWeight='bold'>
            Change password
          </Heading>
          <ResetPasswordForm />
        </div>
      </div>
      <footer className='m-auto w-full max-w-7xl'>
        <Footer />
      </footer>
    </div>
  );
};

export default ResetPasswordPage;
