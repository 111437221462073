import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const Item = ({
  icon,
  children,
  onClick,
  href,
}: {
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  href?: string;
}) => {
  const className = classNames('p-4 flex space-x-2 items-center justify-start hover:text-primary', {
    'cursor-pointer': !!onClick,
  });

  const item = (
    <div className={className} onClick={onClick}>
      {icon}
      <div className='flex items-center justify-between w-full'>{children}</div>
    </div>
  );

  const location = useLocation();
  return href ? (
    <Link to={{ pathname: href }} state={{ previousRoute: location.pathname }}>
      {item}
    </Link>
  ) : (
    item
  );
};

Item.role = 'Menu.Item';
Item.displayName = 'Menu.Item';

export default Item;
