import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const counterChangesSchemaLiteral = {
  title: 'counter changes schema',
  version: 1,
  description: 'Represents a counter change',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      minLength: 37,
      maxLength: 37,
    },
    value: {
      type: 'number',
    },
    counter_id: {
      type: 'string',
      minLength: 36,
      maxLength: 36,
    },
    _deleted: {
      type: 'boolean',
    },
    created_at: {
      type: 'string',
      final: true,
    },
    updated_at: {
      type: 'string',
    },
  },
  required: ['id', 'counter_id', 'value'],
} as const;

const schemaTyped = toTypedRxJsonSchema(counterChangesSchemaLiteral);
export type CounterChangesDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const counterChangesSchema: RxJsonSchema<CounterChangesDocumentType> = counterChangesSchemaLiteral;
