import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Button, Heading, Text } from 'components/common/ComponentLibrary/';
import useDeleteUser from 'hooks/users/useDeleteUser';
import { ShieldExclamationIcon } from '@heroicons/react/24/outline';

const ProfileDeleteModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const { isBusy, deleteUser } = useDeleteUser();
  return (
    <BackdropComponent dismiss={onDismiss}>
      <div className='flex items-start flex-col gap-5 w-full p-6'>
        <Heading size='xl' spacing='s'>
          Delete account
        </Heading>
        <div className='flex items-center space-x-5 mb-5'>
          <ShieldExclamationIcon className='w-16 text-red-500' />
          <div>
            <Text fontWeight='bold' size='l'>
              Are you sure you want to delete you account?
            </Text>
            <Text color='red'>
              <i>You will lose all your data and this action cannot be undone!</i>
            </Text>
          </div>
        </div>
        <div className='flex justify-between w-full'>
          <Button size='m' onClick={onDismiss}>
            Cancel
          </Button>
          <Button color='red' onClick={deleteUser} status={isBusy ? 'busy' : ''}>
            Delete account
          </Button>
        </div>
      </div>
    </BackdropComponent>
  );
};

export default ProfileDeleteModal;
