import { NODE_ENVIRONMENT_DEVELOPMENT } from 'constants/application.constants';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';

export function getRootUrl(): string {
  if (process.env.NODE_ENV === NODE_ENVIRONMENT_DEVELOPMENT) {
    return `http://localhost:3000`;
  }

  return `https://tallytabby.com`;
}

export function getUrlTerms(): string {
  return `/terms`;
}

export function getUrlPrivacy(): string {
  return `/privacy`;
}

export function getUrlHome(): string {
  return `/`;
}

export function getUrlCounter(counter: CounterInterface): string {
  return `/counters/${counter.id}`;
}

export function getUrlResetPassword(): string {
  return `/reset-password`;
}

export function getUrlLostPassword(): string {
  return `/lost-password`;
}

export function getUrlSettings(): string {
  return `/settings`;
}

export function getUrlVerifyEmail(email: string): string {
  return `/verify-email?email=${email.toLowerCase()}`;
}
