export type ENV_NAMES =
  | 'ENV'
  | 'SUPABASE_URL'
  | 'SUPABASE_ANON_KEY'
  | 'SUPABASE_PROJECT_REF'
  | 'MIXPANEL_PROJECT_TOKEN';

export const getEnvironmentVariable = (name: ENV_NAMES) => {
  const value = process.env[`REACT_APP_${name}`];
  if (!value) {
    throw new Error(`Environment variable 'REACT_APP_${name}' is not set.`);
  }
  return value;
};
