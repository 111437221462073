import React from 'react';
import SignUpForm from 'components/application/SignUpForm';
import { Login } from 'components/application/Login';

const AuthenticationScreen = () => (
  <div className='flex flex-col space-y-10'>
    <SignUpForm />
    <Login />
  </div>
);

export default AuthenticationScreen;
