import { useEffect } from 'react';
import { THEME } from 'constants/theme.constants';
import { useProfileStore } from 'store/profile.store';

const useTheme = () => {
  const [profile, updateProfile] = useProfileStore((state) => [state.profile, state.updateProfile]);

  const handleSetTheme = async (theme: THEME) => {
    document.body.classList.remove(profile?.asProfile()?.theme as string);
    await updateProfile({ theme });
  };

  useEffect(() => {
    if (profile) {
      if (
        profile.asProfile().theme === THEME.DARK ||
        (profile.asProfile().theme === THEME.SYSTEM && window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.body.classList.add(THEME.DARK);
      } else if (profile.asProfile().theme === THEME.LIGHT) {
        document.body.classList.add(THEME.LIGHT);
      }
    }
  }, [profile]);

  return { setTheme: handleSetTheme, theme: profile?.asProfile()?.theme };
};

export default useTheme;
