import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface Textarea {
  name: string;
  size?: 's' | 'm';
  rows?: number;
  placeholder?: string;
  value?: string;
  required?: boolean;
  status: '' | 'disabled' | 'invalid';
  maxLength?: number;
  onChange?: (arg0: any) => any;
  onClick?: () => any;
}

const Textarea = forwardRef<HTMLTextAreaElement, Textarea>(
  (
    { name, size = 'm', rows = 4, placeholder, value, required = false, status = '', onChange, onClick }: Textarea,
    ref,
  ) => {
    const className = classNames(
      'text-primary border border-neutral-200 dark:placeholder-neutral-600 dark:border-neutral-900 w-full rounded-xl py-1.5 tracking-wider dark:bg-neutral-900 ring-1 ring-gray-200 dark:ring-bright/25 h-full resize-none',
      {
        'ring-transparent focus-within:outline-none focus:ring-primary': status !== 'invalid',
        'ring-transparent border-1 border-amber-600 focus-within:outline-none ': status === 'invalid',
        'text-lg h-12 px-3': size === 'm',
        'text-sm h-10 px-2': size === 's',
        'opacity-50': status === 'disabled',
      },
    );

    return (
      <div className='relative flex w-full grow'>
        <textarea
          ref={ref}
          id={name}
          name={name}
          placeholder={placeholder}
          className={className}
          required={required}
          disabled={status === 'disabled'}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          value={value}
          rows={rows}
          onClick={() => {
            onClick && onClick();
          }}
        />
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
