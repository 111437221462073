import React from 'react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { THEME } from 'constants/theme.constants';
import { Button, Tooltip, Menu, Text } from 'components/common/ComponentLibrary';
import { capitalizeFirstLetter } from 'utilities/common/strings.utils';
import { useProfileStore } from 'store/profile.store';
import { STYLE } from 'constants/profiles.constants';

const StyleSelector = () => {
  const [settings, updateSettings] = useProfileStore((state) => [state.profile, state.updateProfile]);

  const handleSetStyle = async (style: STYLE) => {
    await updateSettings({ style });
  };

  return (
    <Tooltip
      width='full'
      size='m'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='l'>
          {capitalizeFirstLetter(settings?.style || STYLE.NORMAL)}
          <Button.Icon icon={<ChevronDownIcon className='w-5' />} />
        </Button>
      }
      isInteractive={true}
      hideOnClick={true}
      scope='global'
      trigger='click'
    >
      <Menu>
        <Menu.Item onClick={() => handleSetStyle(STYLE.NORMAL)}>
          <Text color='inherit'>{capitalizeFirstLetter(STYLE.NORMAL)}</Text>
          {settings?.style === THEME.LIGHT && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => handleSetStyle(STYLE.COMPACT)}>
          <Text color='inherit'>{capitalizeFirstLetter(STYLE.COMPACT)}</Text>
          {settings?.style === THEME.DARK && <CheckIcon className='w-5' />}
        </Menu.Item>
      </Menu>
    </Tooltip>
  );
};

export default StyleSelector;
