import { format, formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const formatDistanceToNowInUsersTimezone = (date: string): string => {
  return formatDistanceToNow(new Date(`${date}Z`), { addSuffix: true });
};

export const getCurrentUTCDateFormatted = (): string => {
  const date = new Date();
  const utcDate = utcToZonedTime(date, 'UTC');
  return format(utcDate, "yyyy-MM-dd'T'HH:mm:ss");
};

export const formatSeconds = (seconds: number): string => {
  return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, '0')}`;
};
