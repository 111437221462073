import React, { useState } from 'react';

import { Button, ContentLayout, Heading, Text } from 'components/common/ComponentLibrary';
import ThemeSelector from 'components/settings/ThemeSelector/ThemeSelector';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import Footer from 'components/application/Footer';
import ProfileDeleteModal from 'components/profiles/ProfileDeleteModal';
import Header from 'components/application/Header';
import { StyleSelector } from 'components/settings/StyleSelector';

const SettingsScreen = () => {
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState<boolean>(false);

  useDocumentTitle('Settings');

  return (
    <ContentLayout>
      <ContentLayout.Top>
        <Header />
      </ContentLayout.Top>
      <ContentLayout.Content>
        <div className='m-auto max-w-2xl flex gap-10 flex-col'>
          <Heading size='xxxl' spacing='l'>
            Settings
          </Heading>
          <div className='p-5 md:p-10 rounded-2xl bg-neutral-100 dark:bg-neutral-800 flex gap-10 flex-col'>
            <Heading size='xxl' level={2}>
              Application
            </Heading>
            <div className='flex gap-6 items-center justify-between'>
              <div>
                <Text size='xl' fontWeight='bold'>
                  Application Theme
                </Text>
                <Text size='m'>Overall application theme</Text>
              </div>
              <ThemeSelector />
            </div>
            <div className='flex gap-6 items-center justify-between'>
              <div>
                <Text size='xl' fontWeight='bold'>
                  Counter Theme
                </Text>
                <Text size='m'>Theme of the counter items</Text>
              </div>
              <StyleSelector />
            </div>
          </div>

          <div className='p-5 md:p-10 rounded-2xl bg-neutral-100 dark:bg-neutral-800 flex gap-10 flex-col'>
            <Heading size='xxl' level={2}>
              Account
            </Heading>
            <div className='flex gap-4 items-center justify-between'>
              <div>
                <Text size='xl' fontWeight='bold' color='red'>
                  Delete account
                </Text>
                <Text size='m' color='red'>
                  Delete your account and associated data
                </Text>
              </div>
              <div>
                <Button size='l' width='none' theme='bare' color='red' onClick={() => setShowDeleteAccountModal(true)}>
                  Delete
                </Button>
                {showDeleteAccountModal && <ProfileDeleteModal onDismiss={() => setShowDeleteAccountModal(false)} />}
              </div>
            </div>
          </div>
        </div>
      </ContentLayout.Content>
      <ContentLayout.Footer>
        <Footer />
      </ContentLayout.Footer>
    </ContentLayout>
  );
};

export default SettingsScreen;
