import React, { useState } from 'react';
import { Text, Button } from 'components/common/ComponentLibrary';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { getRandomNumber } from 'utilities/common/number.utils';
import { AnimatedCounter } from './AnimatedCounter';

const CounterDemo = () => {
  const [counterValue, setCounterValue] = useState(getRandomNumber(2200, 3500));

  const handleCount = async (increment: boolean) => {
    const newCount = increment ? counterValue + 1 : counterValue - 1;
    if (newCount >= 0) {
      setCounterValue(newCount);
    }
  };

  return (
    <div className='shadow-[0_4px_15px_rgb(0,0,0,0.22)] md:shadow-[0_2px_10px_rgb(0,0,0,0.22)] drop-shadow-md bg-white dark:bg-neutral-800 p-5 rounded-2xl max-w-xl m-auto md:w-auto flex flex-col gap-2'>
      <div>
        <Text fontWeight='semibold' size='xl' lineClamp={1} textAlign='center'>
          Demo counter
        </Text>
      </div>
      <div className='flex items-center justify-center my-3'>
        <AnimatedCounter counterValue={counterValue} />
      </div>
      <div className='flex items-center justify-between mt-2'>
        <Button
          color='red'
          size='l'
          onClick={(e) => {
            e.preventDefault();
            handleCount(false);
          }}
          status={counterValue === 0 ? 'disabled' : ''}
        >
          <Button.Icon icon={<MinusIcon className='w-8' />} />
        </Button>
        <Button
          size='l'
          onClick={(e) => {
            e.preventDefault();
            handleCount(true);
          }}
        >
          <Button.Icon icon={<PlusIcon className='w-8' />} />
        </Button>
      </div>
    </div>
  );
};

export default CounterDemo;
