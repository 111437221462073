import useInstallPWA from 'hooks/application/useInstallPWA';
import React, { useState } from 'react';
import { Button, Text } from 'components/common/ComponentLibrary';
import Cookies from 'universal-cookie';
import { COOKIE_INSTALL_BANNER } from 'constants/application.constants';

const InstallPWABanner = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { canInstall, installPWA } = useInstallPWA();
  if (!canInstall || !isVisible) {
    return null;
  }

  const handleNotNow = () => {
    const cookies = new Cookies();
    cookies.set(COOKIE_INSTALL_BANNER, 'hidden', { path: '/' });
    setIsVisible(false);
  };

  return (
    <div className='w-full md:w-auto md:max-w-3xl bg-paperdark border border-dark p-5 rounded-tl-2xl fixed bottom-0 right-0'>
      <div className='flex items-center justify-between gap-20'>
        <div>
          <Text fontWeight='bold' size='l'>
            Install TallyTabby
          </Text>
          <Button theme='bare' size='s' width='none' onClick={handleNotNow}>
            Not now
          </Button>
        </div>
        <Button size='m' theme='solid' onClick={installPWA}>
          Install
        </Button>
      </div>
    </div>
  );
};

export default InstallPWABanner;
