import React from 'react';
import Footer from 'components/application/Footer';
import Header from 'components/application/Header';
import { CounterList } from 'components/counters/CounterList';
import { ContentLayout } from 'components/common/ComponentLibrary';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import InstallPWABanner from 'components/common/InstallPWABanner';
import Cookies from 'universal-cookie';
import { COOKIE_INSTALL_BANNER } from 'constants/application.constants';

const HomePage = () => {
  useDocumentTitle('Home');

  const cookies = new Cookies();
  const installBannerCookie = cookies.get(COOKIE_INSTALL_BANNER);

  return (
    <ContentLayout>
      <ContentLayout.Top>
        <Header />
      </ContentLayout.Top>
      <ContentLayout.Content>
        <CounterList />
      </ContentLayout.Content>
      <ContentLayout.Footer>
        {!installBannerCookie && (
          <div className='md:hidden'>
            <InstallPWABanner />
          </div>
        )}
        <Footer />
      </ContentLayout.Footer>
    </ContentLayout>
  );
};

export default HomePage;
