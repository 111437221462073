import React, { forwardRef } from 'react';

import { UserIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface AvatarInterface {
  alt: string;
  src?: string;
  size: 'm' | 'xxl' | 'inherit';
}

const Avatar = forwardRef<HTMLImageElement, AvatarInterface>(({ src, size = 'xxl', alt }: AvatarInterface, ref) => {
  const avatarClassNames = classNames({
    'relative flex items-center justify-center shrink-0 overflow-hidden': true,
    'transition ease-in-out': true,
    'bg-zinc-100 dark:bg-zinc-800': true,
    'rounded-full': true,

    'h-[100%] w-[100%]': size === 'inherit',
    'w-40 h-40': size === 'xxl',
    'w-14 h-14': size === 'm',
  });

  return (
    <div className={avatarClassNames} ref={ref}>
      {!src && <UserIcon className='w-3/4 text-gray-200' />}

      {src && <img src={src} alt={alt} className='w-full h-full object-cover' />}
    </div>
  );
});

Avatar.displayName = 'Avatar';

export default Avatar;
