export const isString = (string: any) => typeof string === 'string' || string instanceof String;

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const shortenString = (string: string, length: number) =>
  string.length > length ? `${string.slice(0, length - 1)}...` : string;
