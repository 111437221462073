import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { database } from 'store/rxdb/database';
import { CounterChangeInterface } from 'models/counters/interfaces/CounterChangeInterface';
import { v4 as uuidv4 } from 'uuid';

export async function createCounterChange(counterId: string, value: number) {
  const newCounterChange: CounterChangeInterface = {
    id: uuidv4().toString(),
    counter_id: counterId,
    value,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @todo-phil I could not fix this TS issue :(
  return await database.counterchanges.insert(newCounterChange);
}

export async function deleteCounterChange(counterChangeId: string) {
  try {
    const doc = await database.counterchanges.findOne({ selector: { id: counterChangeId } }).exec();
    if (!doc) {
      return Promise.reject('No counter change found');
    }

    await doc?.update({
      $set: {
        _deleted: true,
        updated_at: getCurrentUTCDateFormatted(),
      },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getCounterChangesByCounterId(counterId: string) {
  try {
    const doc = await database.counterchanges
      .find({
        selector: {
          counter_id: counterId,
        },
      })
      .sort('-created_at')
      .exec();
    if (!doc) {
      return Promise.reject('No counter changes found');
    }

    return Promise.resolve(doc);
  } catch (e) {
    return Promise.reject(e);
  }
}
