import { RxDocument } from 'rxdb';
import { ProfileDocumentType } from './profile.schema';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import { THEME } from 'constants/theme.constants';
import { STYLE } from 'constants/profiles.constants';

export type ProfileDocumentMethods = {
  asProfile: () => ProfileInterface;
};

export type ProfileDocument = RxDocument<ProfileDocumentType, ProfileDocumentMethods>;

export const defaultProfileDocumentMethods: ProfileDocumentMethods = {
  asProfile: function (this: ProfileDocument) {
    return {
      id: this.id,
      name: this.name,
      theme: this.theme || THEME.SYSTEM,
      style: this.style || STYLE.NORMAL,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  },
};
