import TallyTabbyLogo from 'components/common/TallyTabbyLogo';
import useMousePosition from 'hooks/application/useMousePosition';
import React, { useEffect, useRef, useState } from 'react';
import { getAngleBetween } from 'utilities/common/number.utils';

const TallyTabbyLogoAnimated = () => {
  const catRef = useRef<HTMLDivElement>(null);

  const position = useMousePosition();

  const [angle, setAngle] = useState<number>(0);
  useEffect(() => {
    if (catRef && catRef?.current) {
      const rect = catRef.current.getBoundingClientRect();
      const catX = rect.left + rect.width / 2;
      const catY = rect.top + rect.height / 2;

      if (position.x && position.y) {
        setAngle(getAngleBetween(position.x, position.y, catX, catY));
      }
    }
  }, [position]);

  const styleLeftEye = {
    transform: `rotate(${angle}deg)`,
    transition: `all 0.2s ease`,
  };

  const styleRightEye = {
    transform: `rotate(${angle}deg)`,
    transition: `all 0.2s ease`,
  };

  return (
    <div className='relative' ref={catRef}>
      <TallyTabbyLogo className='w-56 mb-10' />
      <div className='flex items-center gap-7 absolute left-[62px] top-[45px]'>
        <div className='w-9 h-9 bg-white rounded-full' style={styleLeftEye}>
          <div className='w-4 h-4 bg-dark rounded-full m-1' />
        </div>
        <div className='w-9 h-9 bg-white rounded-full' style={styleRightEye}>
          <div className='w-4 h-4 bg-dark rounded-full m-1' />
        </div>
      </div>
    </div>
  );
};

export default TallyTabbyLogoAnimated;
