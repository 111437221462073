import { SupabaseClient } from '@supabase/supabase-js';
import { FeedbackInterface } from '../interfaces/FeedbackInterface';

export const TABLE_NAME = 'feedback';

export class FeedbackService {
  supabaseClient: SupabaseClient;

  constructor(_supabaseClient: SupabaseClient) {
    this.supabaseClient = _supabaseClient;
  }

  async create(description: string, email?: string): Promise<void> {
    const { error } = await this.supabaseClient.from(TABLE_NAME).insert({
      email,
      description,
    });
    if (error) {
      throw new Error(error.message);
    }
  }
}
