import React from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import supabase from 'store/supabase/supabase';
import { useNavigate } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import { LostPassword } from 'components/application/LostPassword';

export const PASSWORD_MIN_LENGTH = 8;

const validationSchema = yup.object().shape({
  emailLogin: yup.string().email().trim().required('Enter your email'),
  password: yup
    .string()
    .trim()
    .min(PASSWORD_MIN_LENGTH, `Password should be at least ${PASSWORD_MIN_LENGTH} characters`)
    .required('Enter your password'),
});

const LoginForm = () => {
  const navigate = useNavigate();

  return (
    <Formik
      onSubmit={async ({ emailLogin, password }) => {
        const { error } = await supabase.auth.signInWithPassword({
          email: emailLogin.toLowerCase(),
          password,
        });
        if (error) {
          toast.error(error.message);
          return;
        }

        navigate(getUrlHome());
        window.location.reload();
      }}
      initialValues={{ emailLogin: '', password: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-5 w-full'>
            <FormikInputField type='email' name='emailLogin' placeholder='Enter your email address' />
            <div className='relative w-full'>
              <FormikInputField type='password' name='password' placeholder='Enter your password' />
              <div className='flex justify-end pt-2'>
                <LostPassword />
              </div>
            </div>
            <div className='flex justify-end'>
              <Button size='l' type='submit' status={isSubmitting ? 'busy' : ''} width='full'>
                Sign in
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
