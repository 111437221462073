import React from 'react';
import { SunIcon, ComputerDesktopIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { MoonIcon } from '@heroicons/react/24/solid';
import { THEME } from 'constants/theme.constants';
import { Button, Tooltip, Menu, Text } from 'components/common/ComponentLibrary';
import useTheme from 'hooks/application/useTheme';
import { capitalizeFirstLetter } from 'utilities/common/strings.utils';

const ThemeSelector = () => {
  const { setTheme, theme } = useTheme();

  const handleSetTheme = async (theme: THEME) => {
    await setTheme(theme);
  };

  return (
    <Tooltip
      width='full'
      size='m'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='l'>
          {capitalizeFirstLetter(theme)}
          <Button.Icon icon={<ChevronDownIcon className='w-5' />} />
        </Button>
      }
      isInteractive={true}
      hideOnClick={true}
      scope='global'
      trigger='click'
    >
      <Menu>
        <Menu.Item onClick={() => handleSetTheme(THEME.LIGHT)} icon={<SunIcon className='w-5' />}>
          <Text color='inherit'>{capitalizeFirstLetter(THEME.LIGHT)}</Text>
          {theme === THEME.LIGHT && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => handleSetTheme(THEME.DARK)} icon={<MoonIcon className='w-5' />}>
          <Text color='inherit'>{capitalizeFirstLetter(THEME.DARK)}</Text>
          {theme === THEME.DARK && <CheckIcon className='w-5' />}
        </Menu.Item>
        <Menu.Item onClick={() => handleSetTheme(THEME.SYSTEM)} icon={<ComputerDesktopIcon className='w-5' />}>
          <Text color='inherit'>{capitalizeFirstLetter(THEME.SYSTEM)}</Text>
          {theme === THEME.SYSTEM && <CheckIcon className='w-5' />}
        </Menu.Item>
      </Menu>
    </Tooltip>
  );
};

export default ThemeSelector;
