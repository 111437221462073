import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import EditCounterForm from './EditCounterForm';
import { Heading } from 'components/common/ComponentLibrary';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import ReactDOM from 'react-dom';

const EditCounterModal = ({ onDismiss, counter }: { onDismiss: () => void; counter: CounterInterface }) =>
  ReactDOM.createPortal(
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full p-6'>
        <Heading size='xl' textAlign='left' spacing='m'>
          Update counter
        </Heading>
        <EditCounterForm onChanged={onDismiss} counter={counter} />
      </div>
    </BackdropComponent>,
    document.getElementById('modal-root')!,
  );

export default EditCounterModal;
