import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { database } from './rxdb/database';
import { ProfileInterface } from 'models/profiles/interfaces/ProfileInterface';
import { ProfileDocument } from './rxdb/profile/profile.document';

export type IProfile = {
  profile: ProfileDocument | null;
  getProfile: () => Promise<ProfileDocument | undefined>;
  setProfile: (profile: ProfileDocument | null) => void;
  updateProfile: (data: Partial<ProfileInterface>) => Promise<void>;
};

export const useProfileStore = create<IProfile>((set) => ({
  profile: null,

  getProfile: async () => {
    try {
      const settings = await database.profiles.findOne().exec();
      return settings as unknown as ProfileDocument;
    } catch (e) {
      return undefined;
    }
  },

  setProfile: (profile) =>
    set((state) => ({
      ...state,
      profile,
    })),

  updateProfile: async (profile: Partial<ProfileInterface>) => {
    try {
      const query = await database.profiles.findOne().exec();
      const updatedDocument = await query?.update({
        $set: {
          ...profile,
        },
      });

      set((state) => ({
        ...state,
        profile: updatedDocument as unknown as ProfileDocument,
      }));
    } catch (e) {
      return undefined;
    }
  },
}));

mountStoreDevtool('ProfileStore', useProfileStore);
