import { useState } from 'react';
import { useAuthStore } from 'store/auth.store';
import { v4 as uuidv4 } from 'uuid';
import { createCounter } from 'models/counters/services/rxDb/RxDbCounterService';

const useCreateCounter = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [currentUser] = useAuthStore((state) => [state.currentUser]);

  const handleCreateCounter = async (name: string, description?: string | null) => {
    if (!currentUser) {
      return;
    }

    setIsBusy(true);

    const counter = await createCounter({
      id: uuidv4().toString(),
      name,
      description: description || '',
      user_id: currentUser.id,
      count: 0,
    });

    setIsBusy(false);

    return counter;
  };

  return {
    createCounter: handleCreateCounter,
    isBusy,
  };
};

export default useCreateCounter;
