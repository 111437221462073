import { motion } from 'framer-motion';
import React, { ComponentPropsWithoutRef } from 'react';
import { Text } from 'components/common/ComponentLibrary';

type Props = ComponentPropsWithoutRef<'img'>;

const LoadingIndicator: React.FC<Props> = (props) => {
  const { className = '', ...imgProps } = props;

  return (
    <motion.div
      className='flex gap-4 flex-col flex-1 h-screen items-center justify-center'
      animate={{ opacity: 0.25 }}
      transition={{ ease: 'linear', duration: 1, repeat: Infinity, repeatType: 'reverse' }}
    >
      <img
        alt='loading...'
        src={`${process.env.PUBLIC_URL}/logo.png`}
        className={`w-1/6 min-w-[50px] aspect-square object-contain ${className}`}
        {...imgProps}
      />
      <Text size='xxxl' textAlign='center'>
        Loading...
      </Text>
    </motion.div>
  );
};

export default LoadingIndicator;
