import { CurrentUser } from 'models/current-user.model';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import supabase from './supabase/supabase';
import { database } from './rxdb/database';
import { Session } from '@supabase/supabase-js';

export type IAuthStore = {
  isAuthenticated: boolean;
  currentUser: CurrentUser | null;
  session: Session | null;
  setCurrentUser: (user: CurrentUser, session: Session) => void;
  logout: () => Promise<void>;
};

export const useAuthStore = create<IAuthStore>((set) => ({
  isAuthenticated: false,
  currentUser: null,
  session: null,
  setCurrentUser: (user, session) =>
    set((state) => ({
      ...state,
      isAuthenticated: true,
      currentUser: user,
      session,
    })),
  logout: async () => {
    try {
      await supabase.auth.signOut();

      const collections = Object.values(database.collections);
      for (const collection of collections) {
        await collection.remove();
      }

      set((state) => ({
        ...state,
        isAuthenticated: false,
        currentUser: null,
      }));
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
}));

mountStoreDevtool('AuthStore', useAuthStore);
