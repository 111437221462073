import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const countersSchemaLiteral = {
  title: 'counters schema',
  version: 1,
  description: 'Represents a counter',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      minLength: 37,
      maxLength: 37,
    },
    count: {
      type: 'number',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    user_id: {
      type: 'string',
      minLength: 36,
      maxLength: 36,
    },
    _deleted: {
      type: 'boolean',
    },
    created_at: {
      type: 'string',
      final: true,
    },
    updated_at: {
      type: 'string',
    },
  },
  required: ['id', 'user_id', 'name'],
} as const;

const schemaTyped = toTypedRxJsonSchema(countersSchemaLiteral);
export type CountersDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const countersSchema: RxJsonSchema<CountersDocumentType> = countersSchemaLiteral;
