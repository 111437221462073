import { useState } from 'react';
import supabase from 'store/supabase/supabase';
import { useAuthStore } from 'store/auth.store';
import { SUPABASE_EDGE_FUNCTION_DELETE_USER } from 'constants/application.constants';
import { toast } from 'react-toastify';

const useDeleteUser = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [logout] = useAuthStore((state) => [state.logout]);

  const handleDeleteUser = async () => {
    if (isBusy) {
      return;
    }

    setIsBusy(true);
    try {
      const result = await supabase.functions.invoke(SUPABASE_EDGE_FUNCTION_DELETE_USER);
      toast.info('Your account has been deleted!');
      await logout();
      return;
    } catch (error) {
      toast.error('We could not delete your account right now.');
      setIsBusy(false);
      return false;
    }
  };

  return { isBusy, deleteUser: handleDeleteUser };
};

export default useDeleteUser;
