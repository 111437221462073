import React from 'react';
import useInitSession from 'hooks/application/useInitSession';
import App from 'App';
import LoadingIndicator from 'components/common/LoadingIndicator';

const AppContainer: React.FC = () => {
  const { isInitialising } = useInitSession();
  if (isInitialising) {
    return <LoadingIndicator />;
  }

  return <App />;
};

export default AppContainer;
