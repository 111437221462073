import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useAuthStore } from 'store/auth.store';
import LandingPageScreen from './landing/LandingPage.screen';
import PageNotFound from './not-found/PageNotFound.screen';
import SettingsScreen from './settings/Settings.screen';
import VerifyEmailPage from './verify-email/VerifyEmailPage.screen';
import LostPasswordPage from './lost-password/LostPasswordPage.screen';
import ResetPasswordPage from './reset-password/ResetPasswordPage.screen';
import HomePage from './home/HomePage.screen';
import CounterPage from './counter/CounterPage.screen';

const AnimatedRouter = () => {
  const location = useLocation();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const protectedPage = (page: JSX.Element) => (isAuthenticated ? page : <LandingPageScreen />);

  return (
    <AnimatePresence mode='sync'>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={protectedPage(<HomePage />)} />

        <Route path='/settings' element={protectedPage(<SettingsScreen />)} />
        <Route path='/counters/:counterId' element={protectedPage(<CounterPage />)} />

        <Route path='/verify-email' element={<VerifyEmailPage />} />
        <Route path='/lost-password' element={<LostPasswordPage />} />
        <Route path='/reset-password' element={protectedPage(<ResetPasswordPage />)} />

        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRouter;
