import { useEffect, useState } from 'react';
import { ITallyTabbyDatabase, database } from 'store/rxdb/database';

const useRxDb = () => {
  const [db, setDb] = useState<ITallyTabbyDatabase | undefined>();

  useEffect(() => {
    setDb(database);
  }, []);

  return { db };
};

export default useRxDb;
