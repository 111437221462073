import React from 'react';

import findChildByRole from 'utilities/findChildByRole';
import Content from './subComponents/Content';
import Footer from './subComponents/Footer';
import Top from './subComponents/Top';

interface ContentLayoutInterface {
  children: React.ReactNode;
}

const ContentLayout = ({ children }: ContentLayoutInterface) => {
  const top = findChildByRole(children, 'ContentLayout.Top');
  const content = findChildByRole(children, 'ContentLayout.Content');
  const footer = findChildByRole(children, 'ContentLayout.Footer');

  return (
    <div className='m-auto flex min-h-screen flex-col'>
      <header className='m-auto w-full px-5 md:px-10'>{top}</header>
      <main className='m-auto w-full flex-1 px-5 md:px-10 pt-2'>{content}</main>
      <footer className='m-auto w-full px-5 md:px-10'>{footer}</footer>
    </div>
  );
};

export default Object.assign(ContentLayout, { Top, Content, Footer });
