import React, { useState } from 'react';
import { EllipsisVerticalIcon, XMarkIcon, PencilIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import { Button, Tooltip, Menu, Text } from 'components/common/ComponentLibrary';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { useNavigate } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import { deleteCounter } from 'models/counters/services/rxDb/RxDbCounterService';
import { EditCounterModal } from './EditCounterModal';
import { CustomCounterAdjustmentModal } from './CustomCounterAdjustmentModal';

const CounterSettingsMenu = ({ counter }: { counter: CounterInterface }) => {
  const [showEditCounterModal, setShowEditCounterModal] = useState<boolean>(false);
  const [showCustomCounterAdjustmentModal, setShowCustomCounterAdjustmentModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleDeleteCounter = async () => {
    deleteCounter(counter.id);

    navigate(getUrlHome());
  };

  return (
    <div>
      <Tooltip
        width='full'
        size='m'
        spacing='none'
        target={
          <Button theme='bare' width='none' size='m' onClick={(e: any) => e.preventDefault()}>
            <Button.Icon icon={<EllipsisVerticalIcon className='w-8' />} />
          </Button>
        }
        isInteractive={true}
        hideOnClick={true}
        scope='global'
        trigger='click'
        placement='bottom'
      >
        <Menu>
          <Menu.Item
            onClick={() => setShowCustomCounterAdjustmentModal(true)}
            icon={<ArrowTrendingUpIcon className='w-8' />}
          >
            <Text color='inherit' size='l'>
              Custom adjustment
            </Text>
          </Menu.Item>
          <Menu.Item onClick={() => setShowEditCounterModal(true)} icon={<PencilIcon className='w-8' />}>
            <Text color='inherit' size='l'>
              Edit
            </Text>
          </Menu.Item>
          <Menu.Item onClick={handleDeleteCounter} icon={<XMarkIcon className='w-8' />}>
            <Text color='inherit' size='l'>
              Delete
            </Text>
          </Menu.Item>
        </Menu>
      </Tooltip>
      {showEditCounterModal && <EditCounterModal onDismiss={() => setShowEditCounterModal(false)} counter={counter} />}
      {showCustomCounterAdjustmentModal && (
        <CustomCounterAdjustmentModal onDismiss={() => setShowCustomCounterAdjustmentModal(false)} counter={counter} />
      )}
    </div>
  );
};

export default CounterSettingsMenu;
