import React, { useState } from 'react';
import {
  Bars4Icon,
  PlusIcon,
  HomeIcon,
  Cog6ToothIcon,
  ArrowLeftStartOnRectangleIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { Button, Tooltip, Menu, Text } from 'components/common/ComponentLibrary';
import { useNavigate } from 'react-router-dom';
import { getUrlHome, getUrlSettings } from 'utilities/urls.utils';
import { CreateCounterModal } from 'components/counters/CreateCounterModal';
import { toast } from 'react-toastify';
import { useAuthStore } from 'store/auth.store';
import useInstallPWA from 'hooks/application/useInstallPWA';

const HeaderMenu = () => {
  const [showCreateCounterModal, setShowCreateCounterModal] = useState<boolean>(false);

  const { canInstall, installPWA } = useInstallPWA();

  const navigate = useNavigate();
  const [logout] = useAuthStore((state) => [state.logout]);
  const signOut = () => {
    logout()
      .then(() => navigate(getUrlHome()))
      .catch(() => {
        toast.error('Error signing out.');
      });
  };

  return (
    <div>
      <Tooltip
        width='full'
        size='m'
        spacing='none'
        target={
          <Button theme='bare' width='none' size='l'>
            <Button.Icon icon={<Bars4Icon className='w-8' />} />
          </Button>
        }
        isInteractive={true}
        hideOnClick={true}
        scope='global'
        trigger='click'
      >
        <Menu>
          <Menu.Item href={getUrlHome()} icon={<HomeIcon className='w-8' />}>
            <Text color='inherit' size='l'>
              Home
            </Text>
          </Menu.Item>
          <Menu.Item href={getUrlSettings()} icon={<Cog6ToothIcon className='w-8' />}>
            <Text color='inherit' size='l'>
              Settings
            </Text>
          </Menu.Item>
          <Menu.Item onClick={() => setShowCreateCounterModal(true)} icon={<PlusIcon className='w-8' />}>
            <Text color='inherit' size='l'>
              New counter
            </Text>
          </Menu.Item>
          {canInstall && (
            <Menu.Item onClick={installPWA} icon={<ArrowDownTrayIcon className='w-8' />}>
              <Text color='inherit' size='l'>
                Install TallyTabby
              </Text>
            </Menu.Item>
          )}
          <Menu.Item onClick={() => signOut()} icon={<ArrowLeftStartOnRectangleIcon className='w-8' />}>
            <Text color='inherit' size='l'>
              Logout
            </Text>
          </Menu.Item>
        </Menu>
      </Tooltip>
      {showCreateCounterModal && <CreateCounterModal onDismiss={() => setShowCreateCounterModal(false)} />}
    </div>
  );
};

export default HeaderMenu;
