import { RxDocument } from 'rxdb';
import { CounterChangesDocumentType } from './counterChanges.schema';
import { CounterChangeInterface } from 'models/counters/interfaces/CounterChangeInterface';

export type CounterChangesDocumentMethods = {
  asCounterChange: () => CounterChangeInterface;
};
export type CounterChangesDocument = RxDocument<CounterChangesDocumentType, CounterChangesDocumentMethods>;

export const defaultCounterChangesDocumentMethods: CounterChangesDocumentMethods = {
  asCounterChange: function (this: CounterChangesDocument) {
    return {
      id: this.id,
      counter_id: this.counter_id,
      value: this.value,
      _deleted: this._deleted,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  },
};
