import { RxCollection } from 'rxdb';
import { CountersDocumentType } from './counters.schema';
import { CounterDocumentMethods, CountersDocument } from './counters.document';

export type CounterCollectionMethods = {
  queryAll: () => Promise<CountersDocument[]>;
};

export type CountersCollection = RxCollection<CountersDocumentType, CounterDocumentMethods, CounterCollectionMethods>;

export const defaultCountersCollectionsMethods: CounterCollectionMethods = {
  queryAll: async function (this: CountersCollection) {
    return this.find().sort('-created_at').exec();
  },
};
