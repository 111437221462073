import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField, FormikTextareaField } from 'components/common/Formik';
import { FeedbackService } from 'models/feedback/services/FeedbackService';
import supabase from 'store/supabase/supabase';

const validationSchema = yup.object().shape({
  email: yup.string().email().trim().nullable(),
  description: yup
    .string()
    .trim()
    .min(2, `Description should be at least 2 characters`)
    .required('Enter your feedback'),
});

const FeedbackForm = ({ onDone }: { onDone: () => void }) => (
  <Formik
    onSubmit={async ({ email, description }) => {
      const feedbackService = new FeedbackService(supabase);
      await feedbackService.create(description.trim(), email.trim().toLowerCase());
      onDone();
    }}
    initialValues={{ email: '', description: '' }}
    validateOnBlur={true}
    validationSchema={validationSchema}
    enableReinitialize={true}
  >
    {({ isSubmitting }) => (
      <Form>
        <div className='space-y-4 w-full'>
          <FormikTextareaField name='description' placeholder='What feedback do you have?' />
          <FormikInputField name='email' placeholder='Your email (optional)' type='text' />
          <div className='flex justify-between'>
            <Button size='m' onClick={onDone} theme='ghost'>
              Cancel
            </Button>
            <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
              Send
            </Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default FeedbackForm;
