import React from 'react';

import { format } from 'date-fns';
import { Hyperlink, Text } from 'components/common/ComponentLibrary';

const Footer = () => (
  <div className='flex flex-col items-center justify-between py-5 md:flex-row'>
    <div>
      <Text color='gray-400' size='s'>{`© ${format(new Date(), 'yyyy')} TallyTabby`}</Text>
    </div>
    <Text color='gray-400' size='s'>
      Built by{' '}
      <Hyperlink href='https://twitter.com/strange_quirks' target='_blank'>
        strange_quirks
      </Hyperlink>
    </Text>
  </div>
);

export default Footer;
