import React from 'react';
import Footer from 'components/application/Footer';
import { Heading, Text, ContentLayout } from 'components/common/ComponentLibrary';
import AuthenticationScreen from 'components/application/Authentication';
import CounterDemo from 'components/counters/CounterDemo';
import TallyTabbyLogoAnimated from 'components/application/TallyTabbyLogoAnimated';

const LandingPageScreen = () => (
  <ContentLayout>
    <ContentLayout.Content>
      <div className='flex m-auto flex-col md:flex-row items-center justify-between max-w-screen-lg md:mt-20 gap-10'>
        <section className='flex flex-col md:w-2/3 md:items-start items-center'>
          <TallyTabbyLogoAnimated />
          <Heading size='xxxl' fontWeight='bold' level={0} spacing='s'>
            TallyTabby
          </Heading>
          <Heading size='xl' level={2}>
            A simple counting app to keep track of anything in your life.
          </Heading>
        </section>
        <section className='bg-neutral-100 dark:bg-neutral-800 rounded-2xl p-7'>
          <Heading level={2} size='xl' spacing='l'>
            Get started
          </Heading>
          <AuthenticationScreen />
        </section>
      </div>

      <div className='mt-20'>
        <CounterDemo />
      </div>

      <div className='mt-20 max-w-screen-sm m-auto'>
        <Heading size='xl' spacing='m' textAlign='center'>
          Key features
        </Heading>

        <Text size='l' spacing='s' textAlign='center'>
          Works offline
        </Text>
        <Text size='l' spacing='s' textAlign='center'>
          Can be installed on your phone as a Progressive Web Application (PWA)
        </Text>
        <Text size='l' spacing='s' textAlign='center'>
          Mobile friendly
        </Text>
        <Text size='l' spacing='s' textAlign='center'>
          Free and unlimited counters (for now, so sign up soon!)
        </Text>
        <Text size='l' spacing='s' textAlign='center'>
          History of changes
        </Text>
        <Text size='l' spacing='s' textAlign='center'>
          Light and dark themes
        </Text>
        <Text size='l' spacing='s' textAlign='center'>
          More features to be announced soon
        </Text>
      </div>
    </ContentLayout.Content>
    <ContentLayout.Footer>
      <Footer />
    </ContentLayout.Footer>
  </ContentLayout>
);

export default LandingPageScreen;
