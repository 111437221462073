import { useState } from 'react';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { updateCount } from 'models/counters/services/rxDb/RxDbCounterService';
import { createCounterChange } from 'models/counters/services/rxDb/RxDbCounterChangeService';

const useCount = (counter: CounterInterface) => {
  const [counterValue, setCounterValue] = useState(counter.count);

  const handleIncrementCount = async () => {
    const newCount = counterValue + 1;
    setCounterValue(newCount);
    await updateCount(counter.id, newCount);
    await createCounterChange(counter.id, 1);
  };

  const handleDecrementCount = async () => {
    const newCount = counterValue - 1;
    if (newCount >= 0) {
      setCounterValue(newCount);
      await updateCount(counter.id, newCount);
      await createCounterChange(counter.id, -1);
    }
  };

  return {
    counterValue,
    incrementCount: handleIncrementCount,
    decrementCount: handleDecrementCount,
  };
};

export default useCount;
