import React, { useState } from 'react';
import { Text, Button } from 'components/common/ComponentLibrary';
import { CreateCounterModal } from './CreateCounterModal';

const CreateCounterPromo = () => {
  const [showCreateCounterModal, setShowCreateCounterModal] = useState<boolean>(false);

  return (
    <div className='min-h-56 border border-dashed bg-zinc-50 dark:bg-zinc-700 border-zinc-300 dark:border-zinc-500 p-5 rounded-2xl w-full md:w-auto flex flex-col items-center justify-center gap-5'>
      <Text textAlign='center' fontWeight='semibold' size='xxl'>
        Create new counter
      </Text>
      <Button size='m' theme='ghost' onClick={() => setShowCreateCounterModal(true)}>
        Create
      </Button>
      {showCreateCounterModal && <CreateCounterModal onDismiss={() => setShowCreateCounterModal(false)} />}
    </div>
  );
};

export default CreateCounterPromo;
