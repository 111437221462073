'use client';

import React from 'react';

import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { notifyAboutError } from 'utilities/common/notifyAboutError.utils';
import * as yup from 'yup';
import { PASSWORD_MIN_LENGTH } from 'components/application/Login/LoginForm';
import supabase from 'store/supabase/supabase';
import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import { getUrlHome } from 'utilities/urls.utils';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .min(PASSWORD_MIN_LENGTH, `Password should be at least ${PASSWORD_MIN_LENGTH} characters long`)
    .required('Enter a new password'),
});

const ResetPasswordForm = () => {
  const navigate = useNavigate();

  const onSubmit = async ({ password }: { password: string }) => {
    try {
      const { error } = await supabase.auth.updateUser({ password });
      if (error) {
        toast.error('Your password could not be reset right now.');
        return;
      }

      toast.success('Password reset successfully!');

      navigate(getUrlHome());
    } catch (error) {
      notifyAboutError(error, true);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ password: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div>
            <FormikInputField type='password' name='password' placeholder='Enter a new password' />
            <div className='flex justify-end pt-4'>
              <Button size='m' width='full' type='submit' status={isSubmitting ? 'busy' : ''}>
                Change password
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
