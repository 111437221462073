import { Variants, motion } from 'framer-motion';
import React, { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const dialogVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

type Props = {
  dismiss?: () => void;
  theme?: 'default' | 'plain';
} & ComponentPropsWithoutRef<'div'>;

const BackdropComponent: React.FC<Props> = (props) => {
  const { dismiss, theme = 'default', children, ...divProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useHotkeys('esc', () => dismiss && dismiss(), []);
  useEffect(() => {
    if (!dismiss) {
      return;
    }

    const handleClickOutside = ({ target }: MouseEvent) => {
      if (containerRef.current && target && !containerRef.current?.contains(target as Node)) {
        dismiss();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={dialogVariants}
      className='flex top-0 left-0 right-0 bottom-0 fixed h-screen w-screen dark:bg-neutral-800/10 backdrop-blur-lg justify-center items-center z-[501]'
    >
      <div
        ref={containerRef}
        className={
          theme === 'default'
            ? `flex rounded-2xl bg-neutral-100 dark:bg-neutral-800 border-1 border-neutral-200 dark:border-neutral-700`
            : ''
        }
        {...divProps}
      >
        {children}
      </div>
    </motion.div>
  );
};

export default BackdropComponent;
