import { createClient } from '@supabase/supabase-js';
import { getEnvironmentVariable } from 'utilities/env.utils';

export const PROVIDER_GOOGLE = 'google';

const supabase = createClient(
  `https://${getEnvironmentVariable('SUPABASE_PROJECT_REF')}.supabase.co`,
  getEnvironmentVariable('SUPABASE_ANON_KEY'),
);

export default supabase;
