import { useEffect, useState } from 'react';
import { CountersDocument } from 'store/rxdb/counters/counters.document';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { getCounterById } from 'models/counters/services/rxDb/RxDbCounterService';

const useCounter = (id: string) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docCounter, setDocCounter] = useState<CountersDocument | undefined>(undefined);
  const [counter, setCounter] = useState<CounterInterface | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const retrievedCounter = await getCounterById(id);
        setDocCounter(retrievedCounter);
        setCounter(retrievedCounter.asCounter());
        setIsLoading(false);
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
        return;
      }
    })();

    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    const sub = docCounter?.$.subscribe((current) => setCounter(current.asCounter()));
    return () => {
      sub?.unsubscribe();
    };
  }, [docCounter]);

  return {
    counter,
    isLoading,
    error,
  };
};

export default useCounter;
