import { useEffect, useState } from 'react';
import supabase from 'store/supabase/supabase';
import useRxDb from './useRxDb';
import { useProfileStore } from 'store/profile.store';
import { useAuthStore } from 'store/auth.store';

const POLL_INTERVAL = 1000; // 1 second.

const useInitSession = () => {
  const [isInitialising, setIsInitialising] = useState<boolean>(true);

  const [getProfile, setProfile] = useProfileStore((state) => [state.getProfile, state.setProfile]);
  const [setCurrentUser, logout] = useAuthStore((state) => [state.setCurrentUser, state.logout]);

  useEffect(() => {
    (async () => {
      const {
        data: { session: authSession },
      } = await supabase.auth.getSession();
      if (!authSession) {
        setIsInitialising(false);
        return;
      }

      setCurrentUser(authSession.user, authSession);
    })();
  }, []);

  const { db } = useRxDb();

  useEffect(() => {
    if (db) {
      (async () => {
        const pollData = async () => {
          const dbProfile = await getProfile();
          if (dbProfile) {
            setProfile(dbProfile);
            setIsInitialising(false);
          } else {
            setTimeout(pollData, POLL_INTERVAL);
          }
        };

        await pollData();
      })();
    }
  }, [db]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      switch (event) {
        case 'INITIAL_SESSION':
        case 'SIGNED_IN':
          if (!session) {
            logout();
          }
          break;
        default:
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { isInitialising };
};

export default useInitSession;
