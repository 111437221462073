import React from 'react';

import useCounterChanges from 'hooks/counters/useCounterChanges';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { format } from 'date-fns';
import { Text, Heading } from 'components/common/ComponentLibrary';
import { CounterChangeInterface } from 'models/counters/interfaces/CounterChangeInterface';

const CounterChanges = ({ counter }: { counter: CounterInterface }) => {
  const { counterChanges, isLoading, error } = useCounterChanges(counter);
  if (isLoading || error || !counterChanges || counterChanges.length === 0) {
    return null;
  }

  return (
    <div>
      <Heading spacing='l' size='xl'>
        Change history
      </Heading>
      <div className='gap divide-y divide-neutral-300 dark:divide-neutral-700 gap-10'>
        {counterChanges.map((counterChange: CounterChangeInterface) => (
          <div className='flex py-2 items-center justify-between' key={counterChange.id}>
            {counterChange.created_at && (
              <Text size='l'>{format(new Date(counterChange.created_at + 'Z'), "do MMMM, yyyy 'at' HH:mm:ss")}</Text>
            )}
            <Text size='xl' fontWeight='bold'>
              {counterChange.value}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CounterChanges;
