import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { updateCount } from 'models/counters/services/rxDb/RxDbCounterService';
import { createCounterChange } from 'models/counters/services/rxDb/RxDbCounterChangeService';

const validationSchema = yup.object().shape({
  change: yup.number().required('Enter a number to increment or decrement the counter by'),
});

const getButtonCopy = (value: number) => {
  if (value < 0) {
    return 'Decrease';
  }

  if (value > 0) {
    return 'Increase';
  }

  return 'Change';
};

const CustomCounterAdjustmentForm = ({ onChanged, counter }: { onChanged: () => void; counter: CounterInterface }) => (
  <Formik
    onSubmit={async ({ change }) => {
      if (change === 0) {
        return;
      }

      await updateCount(counter.id, counter.count + change);
      await createCounterChange(counter.id, change);
      onChanged();
    }}
    initialValues={{ change: 0 }}
    validateOnBlur={true}
    validationSchema={validationSchema}
    enableReinitialize={true}
  >
    {({ isSubmitting, values }) => (
      <Form>
        <div className='space-y-6 w-full'>
          <FormikInputField name='change' placeholder='e.g. 7' type='number' />
          <div className='flex justify-between'>
            <Button size='m' onClick={onChanged} theme='ghost'>
              Cancel
            </Button>
            <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
              {getButtonCopy(values.change)}
            </Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default CustomCounterAdjustmentForm;
