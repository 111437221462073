import { useEffect, useState } from 'react';
import useRxDb from 'hooks/application/useRxDb';
import { getAllCounters } from 'models/counters/services/rxDb/RxDbCounterService';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';

const useCounters = () => {
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counters, setCounters] = useState<CounterInterface[]>([]);
  const { db } = useRxDb();

  useEffect(() => {
    setIsLoading(true);
    getAllCounters()
      .then((docs) => docs.map((doc) => doc.asCounter()))
      .then(setCounters)
      .catch(setError)
      .finally(() => setIsLoading(false));
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!db) return;

    const subscription = db.counters.$.subscribe((event) => {
      const newCounter = {
        id: event.documentId,
        count: event.documentData.count || 0,
        name: event.documentData.name,
        description: event.documentData.description,
        updated_at: event.documentData.updated_at,
        created_at: event.documentData.created_at,
        user_id: event.documentData.user_id,
      };

      let updatedCounters = [];

      switch (event.operation) {
        case 'INSERT':
        case 'UPDATE':
          updatedCounters = [
            ...(event.operation === 'INSERT'
              ? counters
              : counters.filter((counter) => counter.id !== event.documentId)),
            newCounter,
          ];
          break;
        case 'DELETE':
          updatedCounters = counters.filter((counter) => counter.id !== event.documentId);
          break;
      }

      updatedCounters.sort(
        (a: CounterInterface, b: CounterInterface) =>
          new Date(b.created_at as any).getTime() - new Date(a.created_at as any).getTime(),
      );

      setCounters(updatedCounters);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [db, counters]);

  return {
    isLoading,
    error,
    counters,
  };
};

export default useCounters;
