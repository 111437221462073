import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import FeedbackForm from './FeedbackForm';
import { Heading, Text } from 'components/common/ComponentLibrary';

const FeedbackModal = ({ onDismiss }: { onDismiss: () => void }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='w-full p-6 md:min-w-96'>
      <Heading size='xl' textAlign='left' spacing='s'>
        Feedback
      </Heading>
      <Text spacing='m'>Send us any feedback or feature requests.</Text>
      <FeedbackForm onDone={onDismiss} />
    </div>
  </BackdropComponent>
);

export default FeedbackModal;
