import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { Text } from 'components/common/ComponentLibrary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InboxArrowDownIcon } from '@heroicons/react/24/solid';
import { getUrlHome } from 'utilities/urls.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

type Props = ComponentPropsWithoutRef<'div'>;

const VerifyEmailPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useDocumentTitle('Verify email');

  useEffect(() => {
    if (!searchParams.get('email')) {
      navigate(getUrlHome());
      return;
    }
  }, []);

  return (
    <div className='m-auto flex min-h-screen'>
      <div className='m-auto max-w-xl'>
        <div className='space-y-4 px-8'>
          <InboxArrowDownIcon className='m-auto w-28 text-center text-dark' />
          <Text textAlign='center' size='xxl' fontWeight='bold'>
            Confirm your email
          </Text>
          <Text textAlign='center' size='l'>
            We've sent an email to <strong>{searchParams.get('email')}</strong> to verify your email address and
            activate your account.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
