import React, { forwardRef } from 'react';
import classNames from 'classnames';

type IInputProps = {
  name?: string;
  size?: 's' | 'm' | 'l';
  status?: '' | 'disabled' | 'invalid';
} & Omit<React.ComponentPropsWithRef<'input'>, 'size'>;

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ name, id, size = 'm', status = '', ...rest }: IInputProps, ref) => {
    const inputClassNames = classNames(
      'text-primary border border-neutral-200 dark:placeholder-neutral-600 dark:border-neutral-900 w-full rounded-xl py-1.5 tracking-wider dark:bg-neutral-900 ring-1 ring-gray-200 dark:ring-bright/25',
      {
        'ring-transparent focus-within:outline-none focus:ring-primary': status !== 'invalid',
        'ring-transparent border-1 border-amber-600 focus-within:outline-none ': status === 'invalid',
        'text-xl h-14 px-5': size === 'l',
        'text-lg h-12 px-3': size === 'm',
        'text-sm h-10 px-2': size === 's',
        'opacity-50': status === 'disabled',
      },
    );

    return (
      <div className='w-full'>
        <input ref={ref} id={name ?? id} name={name} className={inputClassNames} {...rest} />
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
