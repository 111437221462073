import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import CreateCounterForm from './CreateCounterForm';
import { Heading } from 'components/common/ComponentLibrary';

const CreateCounterModal = ({ onDismiss }: { onDismiss: () => void }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='w-full p-6'>
      <Heading size='xl' textAlign='left' spacing='m'>
        Create new counter
      </Heading>
      <CreateCounterForm onChanged={onDismiss} />
    </div>
  </BackdropComponent>
);

export default CreateCounterModal;
