import { RxDocument } from 'rxdb';
import { CountersDocumentType } from './counters.schema';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';

export type CounterDocumentMethods = {
  asCounter: () => CounterInterface;
};
export type CountersDocument = RxDocument<CountersDocumentType, CounterDocumentMethods>;

export const defaultCountersDocumentMethods: CounterDocumentMethods = {
  asCounter: function (this: CountersDocument) {
    return {
      id: this.id,
      user_id: this.user_id,
      name: this.name,
      description: this.description,
      count: this.count || 0,
      _deleted: this._deleted,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  },
};
