import React from 'react';

import { Text } from 'components/common/ComponentLibrary';
import { getUrlLostPassword } from 'utilities/urls.utils';
import { Link } from 'react-router-dom';

const LostPassword = () => {
  return (
    <Link to={getUrlLostPassword()}>
      <Text size='s' color='gray-400'>
        Forgot password?
      </Text>
    </Link>
  );
};

export default LostPassword;
