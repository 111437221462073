import React from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import {
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
} from 'constants/counters.constants';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { updateCounter } from 'models/counters/services/rxDb/RxDbCounterService';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(NAME_MIN_LENGTH, `Name should be at least ${NAME_MIN_LENGTH} characters`)
    .max(NAME_MAX_LENGTH, `Name should be at most ${NAME_MAX_LENGTH} characters`)
    .required('Enter your name'),
  description: yup
    .string()
    .trim()
    .min(DESCRIPTION_MIN_LENGTH, `Description should be at least ${DESCRIPTION_MIN_LENGTH} characters`)
    .max(DESCRIPTION_MAX_LENGTH, `Description should be at most ${DESCRIPTION_MAX_LENGTH} characters`)
    .nullable(),
});

const EditCounterForm = ({ onChanged, counter }: { onChanged: () => void; counter: CounterInterface }) => (
  <Formik
    onSubmit={async ({ name, description }) => {
      await updateCounter(counter.id, {
        name,
        description,
      });
      onChanged();
    }}
    initialValues={{ name: counter.name, description: counter.description || '' }}
    validateOnBlur={true}
    validationSchema={validationSchema}
    enableReinitialize={true}
  >
    {({ isSubmitting }) => (
      <Form>
        <div className='space-y-6 w-full'>
          <FormikInputField name='name' placeholder='Counter name' type='text' label='Name' />
          <FormikInputField
            name='description'
            placeholder='Enter a description (optional)'
            type='text'
            label='Description (optional)'
          />
          <div className='flex justify-between'>
            <Button size='m' onClick={onChanged} theme='ghost'>
              Cancel
            </Button>
            <Button size='m' type='submit' status={isSubmitting ? 'busy' : ''}>
              Update
            </Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default EditCounterForm;
