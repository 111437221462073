import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb';

export const profileSchemaLiteral = {
  title: 'profile schema',
  version: 2,
  description: 'Represents a user',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      minLength: 37,
      maxLength: 37,
    },
    name: {
      type: 'string',
    },
    theme: {
      type: 'string',
    },
    style: {
      type: 'string',
    },
    created_at: {
      type: 'string',
      final: true,
    },
    updated_at: {
      type: 'string',
    },
  },
  required: ['id', 'name', 'created_at'],
} as const;

const schemaTyped = toTypedRxJsonSchema(profileSchemaLiteral);
export type ProfileDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;
export const profileSchema: RxJsonSchema<ProfileDocumentType> = profileSchemaLiteral;
