import React from 'react';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { Text, Button } from 'components/common/ComponentLibrary';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import CounterSettingsMenu from './CounterSettingsMenu';
import { format } from 'date-fns';
import { AnimatedCounter } from './AnimatedCounter';
import useCount from 'hooks/counters/useCount';

const CounterLarge = ({ counter }: { counter: CounterInterface }) => {
  const { counterValue, incrementCount, decrementCount } = useCount(counter);

  const handleCount = async (increment: boolean) => {
    if (increment) {
      await incrementCount();
      return;
    }

    await decrementCount();
  };

  return (
    <div>
      <div className='shadow-[0_4px_15px_rgb(0,0,0,0.22)] md:shadow-[0_2px_10px_rgb(0,0,0,0.22)] drop-shadow-md bg-white dark:bg-neutral-800 p-5 rounded-2xl m-auto md:w-auto flex flex-col gap-2'>
        <div>
          <div className='flex items-center justify-between'>
            <Text fontWeight='semibold' size='xxl'>
              {counter.name}
            </Text>
            <CounterSettingsMenu counter={counter} />
          </div>
          {counter.description && <Text size='l'>{counter.description}</Text>}
        </div>
        <div className='flex items-center justify-center my-5'>
          <AnimatedCounter counterValue={counterValue} size={60} />
        </div>
        <div className='flex items-center justify-between mt-2'>
          <Button
            color='red'
            size='l'
            onClick={(e) => {
              e.preventDefault();
              handleCount(false);
            }}
            status={counterValue === 0 ? 'disabled' : ''}
          >
            <Button.Icon icon={<MinusIcon className='w-8' />} />
          </Button>
          <Button
            size='l'
            onClick={(e) => {
              e.preventDefault();
              handleCount(true);
            }}
          >
            <Button.Icon icon={<PlusIcon className='w-8' />} />
          </Button>
        </div>
      </div>
      {counter.updated_at && (
        <div className='flex items-end justify-end mt-2'>
          <Text color='gray-400'>
            Last updated: {format(new Date(counter.updated_at), "do MMMM, yyyy 'at' HH:mm:ss")}
          </Text>
        </div>
      )}
    </div>
  );
};

export default CounterLarge;
