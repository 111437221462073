import { useEffect, useState } from 'react';
import useRxDb from 'hooks/application/useRxDb';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { getCounterChangesByCounterId } from 'models/counters/services/rxDb/RxDbCounterChangeService';
import { CounterChangeInterface } from 'models/counters/interfaces/CounterChangeInterface';

const useCounterChanges = (counter: CounterInterface) => {
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counterChanges, setCounterChanges] = useState<CounterChangeInterface[]>([]);
  const { db } = useRxDb();

  useEffect(() => {
    setIsLoading(true);
    getCounterChangesByCounterId(counter.id)
      .then((docs) => docs.map((doc: any) => doc.asCounterChange()))
      .then(setCounterChanges)
      .catch(setError)
      .finally(() => setIsLoading(false));
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!db) return;

    const subscription = db.counterchanges.$.subscribe((event) => {
      const newCounter = {
        id: event.documentId,
        value: event.documentData.value || 0,
        counter_id: event.documentData.counter_id,
        updated_at: event.documentData.updated_at,
        created_at: event.documentData.created_at,
      };

      let updatedCounters = [];

      switch (event.operation) {
        case 'INSERT':
        case 'UPDATE':
          updatedCounters = [
            ...(event.operation === 'INSERT'
              ? counterChanges
              : counterChanges.filter((counter) => counter.id !== event.documentId)),
            newCounter,
          ];
          break;
        case 'DELETE':
          updatedCounters = counterChanges.filter((counter) => counter.id !== event.documentId);
          break;
      }

      updatedCounters.sort(
        (a: CounterChangeInterface, b: CounterChangeInterface) =>
          new Date(b.created_at as any).getTime() - new Date(a.created_at as any).getTime(),
      );

      setCounterChanges(updatedCounters);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [db, counterChanges]);

  return {
    isLoading,
    error,
    counterChanges,
  };
};

export default useCounterChanges;
