import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { database } from 'store/rxdb/database';

export async function createCounter(counter: CounterInterface) {
  const newCounter: CounterInterface = {
    ...counter,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @todo-phil I could not fix this TS issue :(
  return await database.counters.insert(newCounter);
}

export async function updateCounter(counterId: string, counter: Partial<CounterInterface>) {
  try {
    const doc = await database.counters.findOne({ selector: { id: counterId } }).exec();
    if (!doc) {
      return Promise.reject('No counter found');
    }

    const updatedDoc = {
      ...counter,
      updated_at: getCurrentUTCDateFormatted(),
    };

    await doc?.update({
      $set: updatedDoc,
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function updateCount(counterId: string, count: number) {
  try {
    const doc = await database.counters.findOne({ selector: { id: counterId } }).exec();
    if (!doc) {
      return Promise.reject('No counter found');
    }

    const updatedDoc = { count, updated_at: getCurrentUTCDateFormatted() };

    await doc?.update({
      $set: updatedDoc,
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function deleteCounter(counterId: string) {
  try {
    const doc = await database.counters.findOne({ selector: { id: counterId } }).exec();
    if (!doc) {
      return Promise.reject('No counter found');
    }

    await doc?.update({
      $set: {
        _deleted: true,
        updated_at: getCurrentUTCDateFormatted(),
      },
    });

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getCounterById(counterId: string) {
  try {
    const doc = await database.counters
      .findOne({
        selector: {
          id: counterId,
        },
      })
      .exec();
    if (!doc) {
      return Promise.reject('No counter found');
    }

    return Promise.resolve(doc);
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllCounters() {
  try {
    return await database.counters.queryAll();
  } catch (e) {
    return Promise.reject(e);
  }
}
