import React from 'react';

import AnimatedNumber from 'react-awesome-animated-number';
import 'react-awesome-animated-number/dist/index.css';
import './AnimatedCounter.css';

const AnimatedCounter = ({ counterValue, size = 50 }: { counterValue: number; size?: number }) => (
  <>
    <AnimatedNumber className='animated-counter' value={counterValue} hasComma={false} minDigits={5} size={size} />
  </>
);

export default AnimatedCounter;
