import React from 'react';
import { getUrlHome } from 'utilities/urls.utils';
import { Button, Heading } from 'components/common/ComponentLibrary';
import useDocumentTitle from 'hooks/application/useDocumentTitle';

const PageNotFound = () => {
  useDocumentTitle('Page not found');

  return (
    <div className='flex flex-col w-full h-screen justify-center items-center'>
      <div className='flex flex-col justify-center items-center text-dark dark:text-white'>
        <Heading size='xxl' level={0} spacing='l'>
          Page not found
        </Heading>
        <div className='flex flex-col text-center items-center justify-center gap-y-1 py-6'>
          <Button size='l' href={getUrlHome()}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
