import LoadingIndicator from 'components/common/LoadingIndicator';
import React, { Suspense } from 'react';
import AnimatedRouter from 'screens/AnimatedRouter.component';
import useIOSVhFix from './hooks/application/useIOSVhFix';
import useTheme from './hooks/application/useTheme';

const App: React.FC = () => {
  useIOSVhFix();
  useTheme();

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <AnimatedRouter />
    </Suspense>
  );
};

export default App;
