import React from 'react';

import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Button } from 'components/common/ComponentLibrary';
import { FormikInputField } from 'components/common/Formik';
import supabase from 'store/supabase/supabase';
import { useNavigate } from 'react-router-dom';
import { getUrlVerifyEmail } from 'utilities/urls.utils';
import { MINIMUM_PW_LENGTH } from 'constants/profiles.constants';

const validationSchema = yup.object().shape({
  email: yup.string().email().trim().required('Enter your email'),
  password: yup
    .string()
    .trim()
    .min(MINIMUM_PW_LENGTH, `Password should be at least ${MINIMUM_PW_LENGTH} characters`)
    .required('Enter a password'),
});

const SignUpForm = () => {
  const navigate = useNavigate();

  return (
    <Formik
      onSubmit={async ({ email, password, name }) => {
        const { error } = await supabase.auth.signUp({
          email: email.toLowerCase(),
          password,
          options: {
            data: {
              full_name: `${name}`,
            },
          },
        });
        if (error) {
          toast.error(error.message);
          return;
        }

        navigate(getUrlVerifyEmail(email));
      }}
      initialValues={{ email: '', password: '', name: '' }}
      validateOnBlur={true}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='space-y-5 w-full md:min-w-96'>
            <FormikInputField type='email' name='email' placeholder='Email e.g. email@example.com' />
            <FormikInputField type='password' name='password' placeholder='Enter a password' />
            <div className='flex justify-end'>
              <Button size='l' type='submit' status={isSubmitting ? 'busy' : ''} width='full'>
                Sign up
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
