import React, { forwardRef } from 'react';

import classNames from 'classnames';

interface IText {
  children: React.ReactNode;
  spacing?: 'none' | 'xs' | 's' | 'm' | 'l' | 'xl';
  size?: 'inherit' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
  tag?: 'span' | 'div' | 'p';
  fontFamily?: 'sans' | 'mono';
  textAlign?: 'none' | 'left' | 'center' | 'right';
  color?: 'inherit' | 'default' | 'red' | 'dark' | 'gray-500' | 'gray-400';
  fontWeight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  transform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
  lineClamp?: 'none' | 1 | 2 | 3 | 4;
}

const Text = forwardRef<HTMLParagraphElement, IText>(
  (
    {
      children,
      spacing = 'none',
      size = 'm',
      tag: Tag = 'p',
      fontWeight = 'normal',
      color = 'default',
      textAlign = 'left',
      fontFamily = 'sans',
      lineClamp = 'none',
      transform = 'none',
    }: IText,
    ref,
  ) => {
    const classNamesComputed = classNames({
      'font-mono': fontFamily === 'mono',
      'font-sans': fontFamily === 'sans',

      'text-left': textAlign === 'left',
      'text-center': textAlign === 'center',
      'text-right': textAlign === 'right',

      'mb-1': spacing === 'xs',
      'mb-2': spacing === 's',
      'mb-4': spacing === 'm',
      'mb-6': spacing === 'l',
      'mb-8': spacing === 'xl',

      'text-xs': size === 'xs',
      'text-sm': size === 's',
      'text-base': size === 'm',
      'text-lg': size === 'l',
      'text-xl tracking-tight': size === 'xl',
      'text-3xl leading-tight tracking-tighter': size === 'xxl',
      'text-5xl leading-tight tracking-tighter': size === 'xxxl',
      'tracking-wide': (size === 's' || size === 'xs') && fontFamily === 'sans',

      'font-light': fontWeight === 'light',
      'font-normal': fontWeight === 'normal',
      'font-medium': fontWeight === 'medium',
      'font-semibold': fontWeight === 'semibold',
      'font-bold': fontWeight === 'bold',

      'text-red-500': color === 'red',
      'text-dark dark:text-white': color === 'default',
      'text-zinc-500': color === 'gray-500',
      'text-zinc-400 dark:text-stone-600': color === 'gray-400',

      'line-clamp-none': lineClamp === 'none',
      'line-clamp-1': lineClamp === 1,
      'line-clamp-2': lineClamp === 2,
      'line-clamp-3': lineClamp === 3,
      'line-clamp-4': lineClamp === 4,

      uppercase: transform === 'uppercase',
      capitalize: transform === 'capitalize',
      lowercase: transform === 'lowercase',
    });

    return (
      <Tag className={classNamesComputed} ref={ref}>
        {children}
      </Tag>
    );
  },
);

Text.displayName = 'Text';

export default Text;
