import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import LazyTippy from './LazyTippy';

interface TooltipInterface {
  children: React.ReactElement;
  target: React.ReactElement;
  spacing?: 'none' | 's' | 'm' | 'l';
  size?: 's' | 'm' | 'l';
  width?: 'auto' | 'full';
  isInteractive?: boolean;
  hideOnClick?: boolean;
  scope?: 'local' | 'global';
  placement?: 'top' | 'bottom-end' | 'bottom';
  trigger?: 'mouseenter focus' | 'click';
}

const Tooltip = ({
  children,
  spacing = 'm',
  target,
  size = 'm',
  width = 'auto',
  placement = 'bottom-end',
  isInteractive = false,
  hideOnClick = false,
  scope = 'local',
  trigger = 'mouseenter focus',
}: TooltipInterface) => {
  const [body, setBody] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setBody(document.body);
  }, []);

  const className = classNames({
    'relative bg-white text-dark dark:text-white dark:bg-neutral-800 border-1 border-dark/25 dark:border-white/25 -mt-2':
      true,

    'p-0': spacing === 'none',
    'px-4 py-2': spacing === 's',
    'p-4': spacing === 'm',
    'p-6': spacing === 'l',

    'rounded-lg max-w-xxs min-w-xxs shadow-lg': size === 's',
    'rounded-xl max-w-xxs shadow-xl': size === 'm',
    'rounded-xl max-w-sm shadow-xl': size === 'l',

    'w-full': width === 'full',
  });

  return (
    <LazyTippy
      content={
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={className}>
            {children}
          </motion.div>
        </AnimatePresence>
      }
      maxWidth='none'
      placement={placement}
      interactive={isInteractive}
      appendTo={scope === 'global' && body ? body : 'parent'}
      trigger={trigger}
      zIndex={20}
      onMount={(instance) => {
        if (hideOnClick) {
          document?.querySelector('[data-tippy-root]')?.addEventListener('click', (event) => {
            instance.hide();
          });
        }
      }}
    >
      {target}
    </LazyTippy>
  );
};

export default Tooltip;
