import React from 'react';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import { Text, Button } from 'components/common/ComponentLibrary';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { getUrlCounter } from 'utilities/urls.utils';
import { AnimatedCounter } from './AnimatedCounter';
import useCount from 'hooks/counters/useCount';

const Counter = ({ counter }: { counter: CounterInterface }) => {
  const { counterValue, incrementCount, decrementCount } = useCount(counter);

  const handleCount = async (increment: boolean) => {
    if (increment) {
      await incrementCount();
      return;
    }

    await decrementCount();
  };

  return (
    <a href={getUrlCounter(counter)}>
      <div className='shadow-[0_4px_15px_rgb(0,0,0,0.12)] md:shadow-[0_2px_10px_rgb(0,0,0,0.12)] drop-shadow-md bg-white dark:bg-neutral-800 p-5 rounded-2xl w-full md:w-auto flex flex-col gap-2'>
        <div>
          <Text fontWeight='semibold' size='l' lineClamp={1} textAlign='center'>
            {counter.name}
          </Text>
        </div>
        <div className='flex items-center justify-center my-3'>
          <AnimatedCounter counterValue={counterValue} />
        </div>
        <div className='flex items-center justify-between mt-2'>
          <Button
            color='red'
            size='l'
            onClick={(e) => {
              e.preventDefault();
              handleCount(false);
            }}
            status={counterValue === 0 ? 'disabled' : ''}
          >
            <Button.Icon icon={<MinusIcon className='w-8' />} />
          </Button>
          <Button
            size='l'
            onClick={(e) => {
              e.preventDefault();
              handleCount(true);
            }}
          >
            <Button.Icon icon={<PlusIcon className='w-8' />} />
          </Button>
        </div>
      </div>
    </a>
  );
};

export default Counter;
