import React from 'react';
import Footer from 'components/application/Footer';
import Header from 'components/application/Header';
import { ContentLayout, Text } from 'components/common/ComponentLibrary';
import { useNavigate, useParams } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';
import useDocumentTitle from 'hooks/application/useDocumentTitle';
import useCounter from 'hooks/counters/useCounter';
import { shortenString } from 'utilities/common/strings.utils';
import PageNotFound from 'screens/not-found/PageNotFound.screen';
import CounterLarge from 'components/counters/CounterLarge';
import { CounterChanges } from 'components/counters/CounterChanges';

const CounterPage = () => {
  const navigate = useNavigate();

  const { counterId } = useParams();
  if (!counterId) {
    navigate(getUrlHome());
    return;
  }

  const { counter, error, isLoading } = useCounter(counterId);

  useDocumentTitle(counter?.name ? shortenString(counter.name, 20) : 'Your counter');

  if (error) {
    return <PageNotFound />;
  }

  if (!counter || isLoading) {
    return null;
  }

  return (
    <ContentLayout>
      <ContentLayout.Top>
        <Header />
      </ContentLayout.Top>
      <ContentLayout.Content>
        <div className='flex flex-col w-full max-w-3xl m-auto gap-10'>
          <CounterLarge counter={counter} />
          <CounterChanges counter={counter} />
        </div>
      </ContentLayout.Content>
      <ContentLayout.Footer>
        <Footer />
      </ContentLayout.Footer>
    </ContentLayout>
  );
};

export default CounterPage;
