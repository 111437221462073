import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import CustomCounterAdjustmentForm from './CustomCounterAdjustmentForm';
import { Heading, Text } from 'components/common/ComponentLibrary';
import { CounterInterface } from 'models/counters/interfaces/CounterInterface';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

const CustomCounterAdjustmentModal = ({ onDismiss, counter }: { onDismiss: () => void; counter: CounterInterface }) => {
  const navigate = useNavigate();

  return ReactDOM.createPortal(
    <BackdropComponent dismiss={onDismiss}>
      <div className='w-full max-w-md p-6'>
        <Heading size='xl' textAlign='left' spacing='s'>
          Increment or decrement
        </Heading>
        <Text spacing='m'>Enter a positive or negative number to either add or subtract from the current count.</Text>
        <CustomCounterAdjustmentForm
          onChanged={() => {
            navigate(0);
            onDismiss();
          }}
          counter={counter}
        />
      </div>
    </BackdropComponent>,
    document.getElementById('modal-root')!,
  );
};

export default CustomCounterAdjustmentModal;
