import React, { useState } from 'react';
import { Text, Button } from 'components/common/ComponentLibrary';
import HeaderMenu from './HeaderMenu';
import { CreateCounterModal } from 'components/counters/CreateCounterModal';
import { PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { FeedbackModal } from 'components/common/Feedback';
import TallyTabbyLogo from 'components/common/TallyTabbyLogo';
import { Link } from 'react-router-dom';
import { getUrlHome } from 'utilities/urls.utils';

const Header = () => {
  const [showCreateCounterModal, setShowCreateCounterModal] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  return (
    <div className='flex items-center justify-between gap-4  py-2 md:py-4'>
      <Link to={{ pathname: getUrlHome() }}>
        <div className='flex items-center gap-4'>
          <TallyTabbyLogo className='w-10' />
          <div className='hidden md:block'>
            <Text fontWeight='bold' size='xxl'>
              TallyTabby
            </Text>
          </div>
        </div>
      </Link>
      <div className='flex items-center gap-4'>
        <div className='hidden md:block'>
          <Button theme='bare' size='s' onClick={() => setShowFeedbackModal(true)}>
            <Button.Icon icon={<QuestionMarkCircleIcon className='w-8' />} />
          </Button>
        </div>
        <Button size='s' onClick={() => setShowCreateCounterModal(true)}>
          <Button.Icon icon={<PlusIcon className='w-5' />} />
          New counter
        </Button>
        <HeaderMenu />
        {showCreateCounterModal && <CreateCounterModal onDismiss={() => setShowCreateCounterModal(false)} />}
        {showFeedbackModal && <FeedbackModal onDismiss={() => setShowFeedbackModal(false)} />}
      </div>
    </div>
  );
};

export default Header;
