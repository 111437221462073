import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import LoginForm from './LoginForm';
import { Heading } from 'components/common/ComponentLibrary';

const LoginModal = ({ onDismiss }: { onDismiss: () => void }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='md:px-20 p-5 md:py-10'>
      <div className='flex flex-col space-y-7 md:w-96'>
        <Heading level={2} size='xl'>
          Sign in
        </Heading>
        <LoginForm />
      </div>
    </div>
  </BackdropComponent>
);

export default LoginModal;
