import numeral from 'numeral';

export const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const formatNumber = (number: number) => (number > 1000 ? numeral(number).format('0,0') : number);

export const getAngleBetween = (cx: number, cy: number, ex: number, ey: number) => {
  const dy = ey - cy;
  const dx = ex - cx;
  const rad = Math.atan2(dy, dx);

  return (rad * 180) / Math.PI;
};
